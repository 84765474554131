import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class UsersapiService {
  url = 'https://young-thicket-95533.herokuapp.com';
  constructor(private http: HttpClient) { }

  getuser() {
  return this.http.get(this.url + '/users');
  }
}
