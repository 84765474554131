import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GetUsersComponent} from './get-users/get-users.component';
import {CreateUsersComponent} from './create-users/create-users.component';


const routes: Routes = [{path:'getusers',component: GetUsersComponent},
{path:'createusers' ,component: CreateUsersComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
